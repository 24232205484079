import request from '@/utils/request'
//const baseURL ='http://test.fba.api.nantang-tech.com'//测试
const baseURL ='http://shipment-api.nantang-tech.com'//正式

//列表
export function getDeliveryList(params) {
  return request({
    url: '/api/fba/get_delivery_list',
    method: "get",
    params,
    baseURL
  })
}
//提货单详情
export function getDeliveryDetail(params) {
  return request({
    url: '/api/fba/get_delivery_detail',
    method: "get",
    params,
    baseURL
  })
}
//导出列表
export function exportDeliveryList(params) {
  return request({
    url: '/api/fba/get_delivery_list',
    method: "get",
    responseType: 'blob',
    params,
    baseURL
  })
}
//导出提货单
export function exportDeliveryOrder(params) {
  return request({
    url: '/api/fba/export_delivery_order',
    method: "get",
    params,
    baseURL
  })
}

//上传附件
export function uploadDeliveryAttach(data) {
  return request({
    url: '/api/fba/upload_delivery_attach',
    method: "post",
    data,
    baseURL,

    headers: {
      "Content-Type": "multipart/form-data"
    },
  })
}

//打印提货单接口
export function print_delivery_order(params) {
  return request({
    url: '/api/fba/print_delivery_order',
    method: "get",
    params,
    baseURL
  })
}

//标记打印状态
export function sign_delivery_order_print(params) {
  return request({
    url: '/api/fba/sign_delivery_order_print',
    method: "get",
    params,
    baseURL
  })
}

// 导出收货人模板
export function exportConsigneeInTemplate(params) {
  return request({
    url: '/api/fba/get_model',
    method: "get",
    responseType: 'blob',
    params,
    baseURL
  })
}

//联系方式配置
export function save_content(data) {
  return request({
    url: '/api/fba/save_content',
    method: "post",
    data,
    baseURL
  })
}

//获取图片列表
export function get_delivery_attach_list(params) {
  return request({
    url: 'api/fba/get_delivery_attach_list',
    method: "get",
    params,
    baseURL
  })
}