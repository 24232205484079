<template>
  <!-- <div class="maximum-box"> -->
  <el-container>
    <el-header>
      <div class="info-box">
        <div class="describe-text">
          <span>发货管理上传图片：</span>
        </div>
      </div>
    </el-header>
    <el-main>
      <div class="subject-box">
        <el-upload
          ref="upLoad_files"
          action="#"
          list-type="picture-card"
          multiple
          :auto-upload="false"
          :before-upload="beforeupload"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :file-list="filearry"
          :headers="{ staffId: staffId, staffName: staffName }"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过9张图片
        </div>
      </div></el-main
    >
    <el-footer>
      <div class="btn">
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="handluCloses">取 消</el-button> -->
          <el-button
            class="submitQCImg"
            type="primary"
            :loading="up_loading"
            @click="confirmPutinStorage()"
            >提交图片</el-button
          >
        </span>
      </div></el-footer
    >
  </el-container>

  <!-- </div> -->
</template>

<script>
import {
  uploadDeliveryAttach,
  get_delivery_attach_list,
} from "@/api/datavalidity";
import {
  loading,
  loadingClose,
  message,
  dateFormat,
  confirmMsg,
} from "@/utils/common";
export default {
  data() {
    return {
      up_loading: false,
      dialogImageUrl: "",
      batch_code: "",
      staffId: "",
      staffName: "",
      data: {},
      fileList: [],
      filearry: [],
      //urlhead: "http://test.fba.api.nantang-tech.com", //测试
       urlhead: "http://shipment-api.nantang-tech.com",//正式
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    get_delivery_attach_list(params) {
      this.filearry = [];
      this.fileList = [];
      get_delivery_attach_list(params)
        .then((res) => {
          if (res.code == 0) {
            if (res.data && res.data.length > 0) {
              res.data.forEach((url) => {
                this.filearry.push({ url: this.urlhead + url });
                this.fileList.push({ url });
              });
            }
          }
        })
        .catch((err) => {});
    },
    handleChange(file, fileList, row) {
      this.fileList = fileList;
      console.log("33:", fileList);
    },
    handleRemove(file, fileList) {
      console.log("44:", fileList);
      this.fileList = fileList;
    },
    httpRequest() {
      let formdata = new FormData();
      let emptyarr = [];
      let arr1 = [],
        arr2 = [];

      this.fileList.forEach((file) => {
        if (file.raw) {
          arr1.push({});
          formdata.append("files[]", file.raw);
        } else {
          arr2.push({});
          formdata.append("uploaded_url[]", file.url);
        }
      });

      if (arr1.length <= 0) {
        formdata.append("files[]", emptyarr);
      }
      if (arr2.length <= 0) {
        formdata.append("uploaded_url[]", emptyarr);
      }

      formdata.append("batch_code", this.batch_code);
      this.up_loading = true;
      uploadDeliveryAttach(formdata)
        .then((res) => {
          console.log("上传图片:", res);
          this.up_loading = false;
          if (res.code == 0) {
            this.$router.push("/finish");
          }
        })
        .catch((err) => {});
    },
    // 获取信息
    getInfo() {
      let batchcode = this.getParmeter("batch_code");
      if (batchcode.includes("#")) {
        this.batch_code = batchcode.split("#")[0];
      } else {
        this.batch_code = batchcode;
      }

      this.staffId = this.getParmeter("id");
      this.staffName = this.getParmeter("userName");

      sessionStorage.setItem("staff-id", this.staffId);
      sessionStorage.setItem("name", this.staffName);
      this.get_delivery_attach_list({ batch_code: this.batch_code });
    },
    // 解析url
    getParmeter(variable) {
      if (window.location.href.split("?")[1].length) {
        let query = window.location.href.split("?")[1]; // 这里query的结果是：   promotion_code=ZB06AKXFSR&sku=100
        let vars = query.split("&"); // 这里vars的结果是：   ["promotion_code=ZB06AKXFSR", "sku=100"]
        for (let i = 0; i < vars.length; i++) {
          //然后循环
          let pair = vars[i].split("="); //  循环第一次结果pair ["promotion_code", "ZB06AKXFSR"]  循环第二次结果pair ["sku", "100"]
          if (pair[0] === variable) {
            // 做判断 如果参数名和我们的实参一样
            console.log(pair, "pair");
            return pair[1]; // 就返回对应的值
          }
        }
        return "";
      }
    },

    // 提交质检图片
    async confirmPutinStorage() {
      this.httpRequest();
    },
    // 文件上传之前
    beforeupload(file) {
      if (file.size > 10485760) {
        console.log("文件过大");
        message("warning", "文件过大,请重新上传少于10M文件！");
        return false;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.img-box {
  width: 100px;
  height: 200px;
  float: left;
  margin-right: 20px;
  // background-color: red;
  img {
    width: 100px;
    height: 100px;
  }
}
.el-header {
  height: 40px !important;
  //  background-color: red;
}
.describe-text {
  line-height: 29px;
}

.info-box {
  height: 20%;
  // background: red;
  // border: 1px solid rgb(223, 217, 217);
}
.subject-box {
  height: 40%;
  // background: yellow;
  // border: 1px solid red;
}
.btn {
  text-align: center;
  .el-button {
    width: 100%;
    font-size: 20px;
  }
}
</style>
